/*! Projet 02-11-2014 */

var addthis_share = {
     // ... other options
     url_transforms : {
          shorten: {
               twitter: 'bitly'
          }
     }, 
     shorteners : {
          bitly : {} 
     }
}

$(document).ready(function() {

    /**
     * Empecher le clic sur les photos du jeux concours
     */
    $(".grid a").click(function(e){
        e.preventDefault();
    });

    /**
     * Slide Makeup
     * 
     */
    var imgPosition = 0;
    $(".list-photos li").each(function(){
        $(this).css('left', imgPosition);
        //Taille img + marge
        imgPosition += 254;
    
    });
    var slidePosition = 0;
    var nbImg = $(".list-photos li").length;

    var nbImgAffiche;
    if (window.matchMedia("(max-width: 648px)").matches) {
      nbImgAffiche = 1;
    } else if (window.matchMedia("(max-width: 992px)").matches) {
      nbImgAffiche = 2;
    } else if (window.matchMedia("(max-width: 1200px)").matches) {
      nbImgAffiche = 3;
    } else {
        nbImgAffiche = 4;
    }

    //Action pour voir les photos suivantes
    $(".next-photos").click(function(e){
        e.preventDefault();
        //Si je suis en bout de slide je stoppe le déplacement
        if(slidePosition >= (nbImg-nbImgAffiche)){ return false; }
        //Pour chaque element de mon slide je change la position
        $(".list-photos li").each(function(index){
            var thisPosition = $(this).position().left;
            var newPosition = thisPosition - 254;
            $(this).css('left', newPosition);
        });
        slidePosition += 1;
    });

    //Action pour voir les photos précédentes
    $(".prev-photos").click(function(e){
        e.preventDefault();
        //Si je suis en bout de slide je stoppe le déplacement
        if(slidePosition <= 0){ return false; }
        //Pour chaque element de mon slide je change la position
        $(".list-photos li").each(function(index){
            var thisPosition = $(this).position().left;
            var newPosition = thisPosition + 254;
            $(this).css('left', newPosition);
        });
        slidePosition -= 1;
    });


    /**
    * Inscription newsletter 
    * 
    */
    $("#subscription-form").submit(function(){
        $.post( 
        "", 
        $( "#subscription-form" ).serialize(),
        function(result) {
            switch (result) {
                case ('ok'):
                    $('.alert').hide();
                    $('.alert-success').show('slow');
                break;
                case ('mail_exixts'):
                    $('.alert').hide();
                   $('.alert-info').show('slow');
                break;
                case ('error'):
                    $('.alert').hide();
                    $('.alert-danger').show('slow');
                break;
            }
        });
        return false;
    });

    /**
     * Navigation ancre dans une page
     * 
     */
    $(".sub-nav").click(function(){
        $('.icons-picto-up').remove();
        var href = $(this).attr('href');
        $('section').removeClass('section-highlight');
        $(href).addClass('section-highlight');
        $(href).find('h1').before('<a href="#top" class="icon icons-picto-up"></a>');
    });

    /**
     * Menu mobile
     * 
     */
    $(".lien-menu-mobile").click(function(e){
        e.preventDefault();
        $(".menu-mobile").show();
    });
    $(".close-menu-mobile").click(function(e){
        e.preventDefault();
        $(".menu-mobile").hide();
    });


    /**
     * Smooth Scrolling
     * 
     */
    $('a[href*=#]:not([href=#])').click(function() {
        if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {

            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top
                }, 2000);
                return false;
            }
        }
    });

    /**
     * Filtre isotope
     * AUTOUR EXPO
     * 
     */
    //Je filtre sur les groupes d'activités
    $('.filtre-groupe a').click(function(e){
        e.preventDefault();
        $('.filtre-groupe').removeClass('selected');
        $('.filtre-activite').removeClass('selected');

        $(".activite").css('display','block');
        $(".groupe").css('display','none');

        var filterValue = $(this).attr('data-filter');
        
        $(filterValue).css('display','block');
        $(this).parent().addClass('selected');
    });
    //Je filtre sur les activités
    $('.filtre-activite a').click(function(e){
        e.preventDefault();
        $('.filtre-groupe').removeClass('selected');
        $('.filtre-activite').removeClass('selected');
        
        $(".groupe").css('display','block');
        $(".activite").css('display','none');

        var filterValue = $(this).attr('data-filter');
        
        $(filterValue).css('display','block');
        $(this).parent().addClass('selected');
    });
    $('.voir-tout').click(function(e){
        e.preventDefault();
        $('.filtre-groupe').removeClass('selected');
        $('.filtre-activite').removeClass('selected');
        $('.weekend, .spectacles, .visites, .conf, .ateliers').css('display','block');
    });

});

/**
 * GOOGLE ANALYTICS
 */
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-54367702-1', 'auto');
ga('send', 'pageview');